import { Component } from 'vue-property-decorator'
import BaseLayoutMixin from '../mixins/baseLayoutMixin'
import WebsiteFooter from '../components/WebsiteFooter.vue'
import WebsiteHeader from '../components/WebsiteHeader.vue'
import MenuItem from '../menuItem.interface'
import { WebsitePaths } from '../../../router/path.constants'

@Component({
  components: {
    WebsiteFooter,
    WebsiteHeader
  }
})
export default class WebsiteLayout extends BaseLayoutMixin {
  isMenuOpen = false

  toggleMenu () {
    this.isMenuOpen = !this.isMenuOpen
  }

  get headerLinks (): MenuItem[] {
    return this.menuItems.filter(m => m.showInHeader && !m.excludeForReaders)
  }

  get footerLinks (): MenuItem[] {
    return this.menuItems.filter(m => m.showInFooter)
  }

  get useBackgroundImage () {
    return this.$q.screen.gt.sm && this.$route.path.includes(WebsitePaths.affiliateProgram)
  }
}
